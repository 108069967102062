body {
    background-color: #42a5f5;
}

.row {
    margin: 0 auto;
    width: 280px;
    clear: both;
    text-align: center;
    font-family: 'Exo';
}

.digit,
.dig {
    float: left;
    padding: 10px 30px;
    width: 30px;
    font-size: 2rem;
    cursor: pointer;
}

.sub {
    font-size: 0.8rem;
    color: grey;
}

.container {
    background-color: white;
    width: 320px;
    padding: 20px;
    margin: 10px auto;
    height: 450px;
    text-align: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#output {
    font-family: "Exo";
    font-size: 2rem;
    height: 60px;
    font-weight: bold;
    color: #1976d2;
}

#call {
    display: inline-block;
    background-color: #66bb6a;
    padding: 4px 30px;
    margin: 10px;
    color: white;
    border-radius: 4px;
    float: left;
    cursor: pointer;
}

#call-slash {
    display: inline-block;
    background-color: #b52424;
    padding: 4px 30px;
    margin: 10px;
    color: white;
    border-radius: 4px;
    float: left;
    cursor: pointer;
}

.botrow {
    margin: 0 auto;
    width: 280px;
    clear: both;
    text-align: center;
    font-family: 'Exo';
}

.digit:active,
.dig:active {
    background-color: #e6e6e6;
}

#call:hover {
    background-color: #81c784;
}
#call-slash:hover {
    background-color: #f00;
    cursor: not-allowed;
}

.dig {
    float: left;
    padding: 10px 20px;
    margin: 10px;
    width: 30px;
    cursor: pointer;
}


input,
span,
label,
textarea {
    font-family: 'Ubuntu', sans-serif;
    display: block;
    margin: 10px;
    padding: 5px;
    border: none;
    font-size: 26px;
    color: #adf542;
    text-align: center;
}

label{
    color: #000 !important;
}
textarea:focus,
input:focus {
    outline: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dialler-label{
    font-size: 30px;
    color: #42a5f5;
}